<template>
  <div
    class="fill-container tw-w-full tw-block md:tw-hidden tw-flex tw-flex-col tw-gap-[24px] tw-mb-[24px] tw-py-[10%] tw-px-[5%]"
  >
    <h1>{{ $t('APP.RETAIL_PORTAL') }}</h1>
  </div>
  <div
    class="retail-login tw-w-full md:tw-w-2/5 tw-flex tw-flex-col tw-gap-[24px] tw-px-[5%]"
  >
    <h1 class="tw-hidden md:tw-block">{{ $t('APP.RETAIL_PORTAL') }}</h1>
    <form class="tw-flex tw-flex-col" @submit.prevent="submitLogin">
      <h3 class="tw-mb-[24px]">{{ $t('APP.SIGN_IN') }}</h3>
      <pn-input
        name="ssid"
        placeholder="Enter Service Point ID..."
        :label="$t('APP.SERVICEPOINT_ID')"
        :helpertext="$t('APP.SSID_TOOLTIP')"
        :disabled="isUsingUnifiedLogin"
        :error="servicepointIdError"
        required="true"
        @blur.capture="blurIdField"
        @input="onSSIDChange"
      />
      <pn-input
        :disabled="isUsingUnifiedLogin"
        name="username"
        label="Username"
        placeholder="Enter Username..."
        required="true"
        @input="onUsernameChange"
      />
      <pn-input
        id="password-input"
        :disabled="isUsingUnifiedLogin"
        name="password"
        label="Password"
        placeholder="Enter Password..."
        required="true"
        type="password"
        autocomplete="current-password"
        @input="onPasswordChange"
      />
      <div class="tw-flex tw-flex-col tw-gap-[24px]">
        <div class="tw-flex tw-flex-row tw-flex-wrap tw-gap-[16px]">
          <pn-button
            v-show="!isUsingUnifiedLogin"
            :label="$t('APP.LOGIN')"
            :loading="loading && !authorization"
            :icon="arrow_right"
            class="tw-w-full xl:tw-w-auto"
            type="submit"
          >
            <p>{{ $t('APP.LOGIN') }}</p>
          </pn-button>
          <pn-button
            v-show="!loading || isUsingUnifiedLogin"
            :loading="isUsingUnifiedLogin"
            appearance="light"
            class="tw-w-full xl:tw-w-auto"
            @click="handleUnifiedLogin"
          >
            <p>{{ $t('APP.PN_LOGIN') }}</p>
          </pn-button>
        </div>
        <pn-text-link
          :href="partnerWebUrl"
          :icon="open_in_new"
          target="_blank"
          class="tw-w-full xl:tw-w-auto tw-pb-8"
        >
          {{ $t('APP.CONTINUE_TO_PARTNERWEB') }}
        </pn-text-link>
        <pn-text-link
          :href="kvittensWebUrl"
          :icon="open_in_new"
          target="_blank"
          class="tw-w-full xl:tw-w-auto tw-pb-8"
        >
          {{ $t('APP.CONTINUE_TO_KVITTENSWEB') }}
        </pn-text-link>
      </div>
    </form>
  </div>
</template>

<script>
import { arrow_right, open_in_new } from '@/shared/constants/icons.js'
import { mapActions, mapGetters, mapState } from 'vuex'
import { useRedirect } from '../composables/useRedirect.js'

export default {
  name: 'HomeLogin',
  setup() {
    const { _returnUrl: returnUrl, isForRedirection } = useRedirect()
    return { returnUrl, isForRedirection }
  },
  data() {
    return {
      ssid: '',
      username: '',
      password: '',
      subscription: null,
      isUsingUnifiedLogin: false,
      arrow_right,
      open_in_new,
      partnerWebUrl: process.env.VUE_APP_PARTNER_WEB_URL,
      kvittensWebUrl: process.env.VUE_APP_KVITTENS_WEB_URL,
      didTouchIdField: false
    }
  },
  computed: {
    ...mapState(['loading']),
    ...mapState('retail', ['authorization']),
    ...mapGetters('retail', ['isLoggedIn']),
    uppercaseInput() {
      return this.ssid.toUpperCase()
    },
    submitEnabled() {
      return (
        this.isValidServicepointId &&
        this.username !== '' &&
        this.password !== ''
      )
    },
    isValidServicepointId() {
      const matchValue = String(this.ssid).match(/(SE|DK|FI|NO)\d{3,}/gim)
      return matchValue !== null && matchValue[0] === this.ssid
    },
    servicepointIdError() {
      return !this.didTouchIdField || this.isValidServicepointId
        ? ''
        : this.$t('APP.SSID_TOOLTIP')
    }
  },
  created() {
    if (this.isLoggedIn) {
      this.processRedirection() //RBO-884 - triggering the redirection earlier so login form does not have to be displayed
    }
  },
  mounted() {
    this.ssid = localStorage.getItem('ssid')
    this.subscription = this.$store.subscribe((mutation) => {
      if (mutation.type === 'retail/didLogin') {
        this.processRedirection()
        this.password = ''
        this.username = ''
      }
    })
  },
  unmounted() {
    this.subscription()
  },
  methods: {
    ...mapActions('retail', ['login']),
    ...mapActions('unified', { loginUsingUnified: 'login' }),
    blurIdField() {
      this.didTouchIdField = true
    },
    submitLogin() {
      this.blurIdField()
      if (this.submitEnabled) {
        localStorage.setItem('ssid', this.ssid)
        this.login({
          ssid: this.uppercaseInput,
          username: this.username,
          password: this.password
        })
      }
    },
    onSSIDChange(event) {
      this.ssid = event.target.value.toUpperCase()
    },
    onUsernameChange(event) {
      this.username = event.target.value
    },
    onPasswordChange(event) {
      this.password = event.target.value
    },
    processRedirection() {
      if (this.isForRedirection) {
        const url = this.getUrlWithToken({
          url: this.returnUrl
        })
        location.assign(url) //RBO-884 - removed timeout to prevent login form from showing if user is logged in
      } else {
        this.$router.push({ path: '/dashboard' })
      }
    },
    handleUnifiedLogin() {
      this.isUsingUnifiedLogin = true
      this.loginUsingUnified()
    }
  }
}
</script>

<style lang="scss" scoped>
.fill-container {
  background-color: $blue25;
}
</style>
