import {
  EXTERNAL_LINKS_IN_SIDEMENU,
  PATH_TO_MENU_ITEM_NAME_MAP,
  SINGLE_ACCESS_POINT_URL_SUFFIX
} from '@/shared/constants/variables'
import { computed, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export function useSingleAccessPoint() {
  const toolUrl = ref(null)
  const iframeOpacity = ref(1)
  const supportsIframe = true
  const manualMenuName = ref(null)
  const currentRouteKeyPath = ref(null)
  const route = useRoute()
  const router = useRouter()

  const isRouteInSideMenuMap = computed(() => {
    return currentRouteKeyPath.value in PATH_TO_MENU_ITEM_NAME_MAP
  })

  const shouldLoadFromIFrame = computed(() => {
    return toolUrl.value && isRouteInSideMenuMap.value
  })

  function isValidURL(string) {
    try {
      new URL(string)
      return true
    } catch (_) {
      return false
    }
  }

  watch(toolUrl, (newVal) => {
    if (isValidURL(newVal)) {
      const convertedUrl = new URL(newVal)
      if (convertedUrl) {
        currentRouteKeyPath.value = convertedUrl.pathname
          ?.replace(SINGLE_ACCESS_POINT_URL_SUFFIX, '')
          .replace(/^\/+|\/+$/g, '')
        manualMenuName.value =
          PATH_TO_MENU_ITEM_NAME_MAP[currentRouteKeyPath.value]
        if (!currentRouteKeyPath.value || !manualMenuName.value) {
          clearIframeReferences()
        }
      }
      iframeOpacity.value = 0
    }
  })

  const initIframe = (url) => {
    if (!supportsIframe) {
      return
    }
    if (
      window.self === window.top &&
      url.includes(SINGLE_ACCESS_POINT_URL_SUFFIX)
    ) {
      toolUrl.value = url.replace(SINGLE_ACCESS_POINT_URL_SUFFIX, '/')
    }
  }

  const handleSideMenuAsGlobalClick = (event) => {
    if (!supportsIframe) {
      return
    }
    const sideMenu = event.target.closest('.side-menu')
    if (sideMenu) {
      const menuItem = event.target.closest('a.side-menu__item')
      if (menuItem) {
        const href = menuItem.getAttribute('href')
        validateUrlToNavigate(href)
      }
    }
  }

  const handleMessageEventAsUrlClick = (event) => {
    if (event.data.action === 'navigate') {
      iframeOpacity.value = 0
      validateUrlToNavigate(event.data.href)
    }
  }

  const validateUrlToNavigate = (url) => {
    if (!isUrlExternalLink(url)) {
      updatePathFromHref(url)
      toolUrl.value = url
    } else {
      window.open(url, '_blank')
    }
  }

  const isUrlExternalLink = (url) => {
    let isExternal = false
    EXTERNAL_LINKS_IN_SIDEMENU.forEach(link => {
      isExternal = isExternal || url.includes(link)
    });
    return isExternal
  }

  const updatePathFromHref = (href) => {
    const url = new URL(href, window.location.origin)
    let path = url.pathname.replace(/^\//, '')
    if (path.length > 1) {
      path = `${SINGLE_ACCESS_POINT_URL_SUFFIX}${path}`
      history.pushState({}, '', path)
    } else {
      history.pushState({}, '', route?.path)
    }
  }

  const clearIframeReferences = () => {
    manualMenuName.value = null
    currentRouteKeyPath.value = null
    toolUrl.value = null
    router.push({ path: '/dashboard' })
  }

  return {
    toolUrl,
    iframeOpacity,
    supportsIframe,
    manualMenuName,
    currentRouteKeyPath,
    shouldLoadFromIFrame,
    isRouteInSideMenuMap,
    initIframe,
    handleSideMenuAsGlobalClick,
    handleMessageEventAsUrlClick,
    validateUrlToNavigate,
    updatePathFromHref,
    clearIframeReferences
  }
}
