import axios from 'axios'
import {
  decoratedRequestWithApiKey,
  decoratedRequestWithCommonHeaders,
  decoratedRequestWithToken
} from './serviceDecorators.js'
import {
  RETAIL_JWT_TOKEN,
} from '@postnord/retail-backoffice-components/dist'

const serviceInstance = axios.create({
  baseURL: `${process.env.VUE_APP_RETAIL_AUTH_URL}`
})

serviceInstance.interceptors.request.use((request) => {
  let newRequest = decoratedRequestWithApiKey(request)
  newRequest = decoratedRequestWithCommonHeaders(newRequest)
  return newRequest
})

// uses regular fetch api instead of axios to get response headers which is not provided by axios
const onPremLogin = async (authorization) => {
    try {
      const url = `${process.env.VUE_APP_HUMANY_LOGIN_URL}?apikey=${process.env.VUE_APP_API_KEY}&response=json`
      const headers = new Headers({ authorization });
      const request = new Request(url, { headers, method: 'GET' });
      let response = null
      await fetch(request)
        .then(response => response.json())
        .then(res => {
          response = res
        })
      return response
    } catch(error) {
      return Promise.reject(error)
    }
}

export default {
  login: async (ssid, username, password) => {
    const payload = JSON.stringify({ password: password })
    return serviceInstance.post(`/login/${ssid}/${username}`, payload)
  },
  /**
   * Exchanges a retail token (SP Login) into a temporary IAM token used
   * for E-shop and Basic Registration
   */
  exchangeToken: async () => {
    serviceInstance.interceptors.request.use((request) => {
      return decoratedRequestWithToken(request)
    })
    return serviceInstance.post('/token', {})
  },
  humanyLogin: async () => {
    const token = localStorage.getItem(RETAIL_JWT_TOKEN)
    const result = await onPremLogin(token)
    return result
  }
}
