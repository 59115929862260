export const traverseCapabilityIfEnabled = (capabilityId, capability) => {
  const props = capabilityId.split('.')
  let counter = 0
  let targetProp = { ...capability }
  const lastIndex = props.length - 1

  while (counter < props.length) {
    if (counter === lastIndex) {
      targetProp = targetProp[`${props[counter]}`]
    } else {
      targetProp = { ...targetProp[`${props[counter]}`] }
    }
    counter += 1
  }

  return typeof targetProp === 'boolean' ? targetProp : false
}

// If route has a capability property then do a checking if none,
// automatically allow
export const filterByCapability = (items, capability) => {
  return items.filter((item) => {
    if (item.meta?.capability && capability) {
      return traverseCapabilityIfEnabled(item.meta.capability, capability)
    }
    return true
  })
}

export const transformedItemsBySpecificCapabilities = (items, capability, market) => {
  return items.map((item) => {
    if (item.name === 'POSTPORTAL.TITLE') {
      if(market == 'DK') {
        item.url = process.env.VUE_APP_POST_PORTAL_DK_URL //RBO-1417 - PostPortal Link Issue for DK
      } else {
        item.url = capability.servicepointBackoffice?.postportalUrl
      }
    }
    return item
  })
}
