import {
  filterByCapability,
  transformedItemsBySpecificCapabilities
} from '@/shared/utils/capabilityUtils'

import { getLocalRetailItems } from './constants/retail'
import { getLocalSpItems } from './constants/servicepoint'
import { addSidemenuMeta } from './utils'
import { useAccessListStore } from '@pn-retail-backoffice/access-lists'
import { piniaInstance } from '../../../store/pinia'

export const getRetailItems = async (store, permission, market) => {
  const retailItems = getLocalRetailItems()
  const isAccesslistEnabled = store.getters['features/isAccesslistEnabled']

  const accessListStore = useAccessListStore(piniaInstance)
  const isProduction = process.env.NODE_ENV === 'production'
  if (isAccesslistEnabled)
    return retailItems
      .filter((item) =>
        item.accessList.some((value) => accessListStore.hasAccess(value))
      )
      .filter(
        (item) => !item.meta?.markets || item.meta?.markets.includes(market)
      )
      .filter((item) => !(isProduction && item.meta?.id === 'testool'))
      .map(addSidemenuMeta)

  return retailItems
    .filter((item) => item.meta?.permission <= permission)
    .filter(
      (item) => !item.meta?.markets || item.meta?.markets.includes(market)
    )
    .filter((item) => !(isProduction && item.meta?.id === 'testool'))
    .map(addSidemenuMeta)
}

export const filterSpItems = async (menuItems, capability, market) => {
  let filteredItems = filterByCapability(menuItems, capability)
  filteredItems = transformedItemsBySpecificCapabilities(
    filteredItems,
    capability,
    market
  )
  return filteredItems.filter(
    (item) => !item.meta?.market || item.meta?.market.includes(market)
  )
}

export const getSpItems = async (capability, market) => {
  const spItems = await filterSpItems(getLocalSpItems(), capability, market)
  return spItems.map(addSidemenuMeta)
}
