<template>
  <div>
    <Toaster
      :hidden="hidden"
      :icon="icon"
      :appearance="appearance"
      :message="message"
      :close-handler="hideToaster"
    />
    <ConfirmModal
      :message="`${$t('APP.LOGOUT_SP')}`"
      :show="showConfirmModal"
      @cancel="showConfirmModal = false"
      @confirm="handleLogout"
    />
    <RetailTopbar
      v-if="isRetailLoggedIn"
      title="Retail Backoffice"
      :show-help="false"
      :is-logged-in="isRetailLoggedIn"
      :user-info="userInfo"
      :country-code="countryCode"
      @logout="showConfirmModal = true"
    />
    <AppLoader v-if="isLoading || isSecuringRoute" />
    <MainContent v-else :selected-lang="selectedLang" />
  </div>
</template>

<script>
import MainContent from '@/shared/components/App/MainContent'
import RetailTopbar from '@/shared/components/App/RetailTopbar'
import { useRouteGuard } from '@/shared/composables/useRouteGuard.js'
import authManagerMixin from '@/shared/mixins/authManagerMixin.js'
import { setupCypress } from '@postnord/retail-backoffice-components/dist'
import { mapActions, mapGetters, mapState } from 'vuex'
import AppLoader from './shared/components/AppLoader.vue'

export default {
  components: {
    MainContent,
    RetailTopbar,
    AppLoader
  },
  mixins: [authManagerMixin],
  setup() {
    if (!window['Cypress']) {
      const { isSecuringRoute } = useRouteGuard()
      return { isSecuringRoute }
    }
  },
  data() {
    return {
      showConfirmModal: false,
      isLoading: true,
      selectedLang: ''
    }
  },
  computed: {
    ...mapState('toaster', [
      'hidden',
      'icon',
      'appearance',
      'message',
      'closable'
    ]),
    ...mapGetters('retail', ['countryCode'])
  },
  mounted() {
    if (window['Cypress']) {
      setupCypress({ store: this.$store })
      this.isLoading = false
    } else if (this.shouldLogout) {
      this.handleAutoLogout()
    } else {
      if (window.pnTopbar?.hasLoaded) {
        this.checkIfUnifiedLoggedIn()
        this.setPnTopbarLanguageChange()
      } else {
        window.addEventListener('topbarLoaded', () => {
          this.checkIfUnifiedLoggedIn()
          this.setPnTopbarLanguageChange()
        })
      }
    }
  },
  methods: {
    ...mapActions(['setLocalizationState']),
    ...mapActions('toaster', ['showToaster', 'hideToaster']),
    setPnTopbarLanguageChange() {
      if (window.pnTopbar) {
        window.pnTopbar.onChangeLanguage = (language) => {
          this.setLocalizationState(language)
          this.$i18n.locale = language
          this.selectedLang = language
          return language
        }
      }
    }
  }
}
</script>
