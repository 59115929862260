<template>
  <div class="tw-relative md:tw-my-[5%] tw-overflow-hidden">
    <div class="background-container tw-size-full tw-hidden md:tw-block">
      <div class="left-part"></div>
      <div class="right-part"></div>
    </div>
    <div
      v-show="!isTopbarLoggedIn"
      class="tw-flex tw-flex-wrap md:tw-mt-[2%] tw-z-1"
    >
      <HomeLogin class="tw-flex-grow-0 md:tw-w-3/5" />
      <div class="tw-flex-grow tw-relative tw-hidden md:tw-block">
        <img
          src="@/assets/svg/character-computer-statistics.svg"
          alt="pn-character-computer-statistics"
          class="tw-absolute tw-bottom-0 tw-left-[25%]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HomeLogin from './components/HomeLogin.vue'

export default {
  components: {
    HomeLogin
  },
  computed: {
    isTopbarLoggedIn() {
      return window.pnTopbar.isLoggedIn
    }
  }
}
</script>

<style lang="scss" scoped>
.background-container {
  position: absolute;
  width: 60%;
  overflow: hidden;
  right: 0;

  .left-part {
    position: absolute;
    width: 844px;
    height: 100%;
    background: url('@/assets/svg/pn-link-bg-left.svg') no-repeat left center;
    background-size: cover;
  }

  .right-part {
    position: absolute;
    left: 844px;
    width: 100%;
    height: 100%;
    background: url('@/assets/svg/pn-link-bg-right.svg') repeat-x;
    background-size: contain;
  }
}
</style>
